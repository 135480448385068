import React from "react";
// import bootstrapStyles from "../assets/styles/bootstrap-grid.module.css";
import styles from "../../assets/styles/brokerCard.module.css";
import cx from "classnames";
import goldIcon from "../../assets/images/gold-broker.png";
import premiumIcon from "../../assets/images/premium-broker.png";
import { Link, navigate } from "gatsby";
import WebpImage from "../WebpImage";
import { formatResponseTime, log } from './../../helpers/commons'

const truncateMsg = (msg, size) => {
  return msg && msg.length > size ? msg.substr(0, size) + "..." : msg;
}

const redirectCard = (props = {}, url = '') => {
  console.log("redirect is clicked")
}

// { card, nodeCountClass, handleBrokerClick }
const BrokerCard = (props) => {
  let card = props.card;

  // let brokerName = capitalize(card.firstName) + " " + capitalize(card.lastName);
  let premiumImg;
  let address = '';
  let feedback = '';
  let stateCode = '';
  let lastIndex;
  feedback = card.broker_details.latest_review;
  let nodeCountClass = props.nodeCountClass;
  feedback=card.feedback
  if (!!feedback) {
    feedback=feedback.substr(0,60); 

    if(feedback.split(' ').length > 7) {
    lastIndex = feedback.lastIndexOf(" ");
    feedback = feedback.substring(0, lastIndex);
    }
  }

  if (card.premium === 3) {
    premiumImg = <img
      className={styles.premiumImg}
      src={goldIcon}
      alt="hashching-gold-broker"
      title="hashching-gold-broker"
    />
  }
  else if (card.premium === 1) {
    premiumImg = <img
      className={styles.premiumImg}
      src={premiumIcon}
      alt="hashching-premium-broker"
      title="hashching-premium-broker"
    />
  }

  return (
    <div
      className={cx(nodeCountClass, styles.brokrDetail)}
      to={card.profile_view}
      onClick={() => {
        if ((!!props.handleBrokerClick)) {
          props.handleBrokerClick(card);
        } 
        else if ((!!props.brokerCardclick)) {
          props.brokerCardclick(card);
        }else {
          navigate(card.profile_view)
        }
      }}
    >
      <div className={styles.brokrDetailInnr}>
        <WebpImage
          className={styles.brokrImg}
          src={card.profile_pic}
          alt={card.fullName}
        />
        <h4 className={styles.brokrNameStatus}>
          <span className={styles.brokrName}>{card.fullName}</span>
          <span className={styles.brokrBadgeStatus}>{premiumImg}</span>
        </h4>
        {(card.review_count > 0) && (<h4 className={styles.totalReviews}>
          <span className={styles.reviewRating}>
            <span className={styles.badge}>
              <svg width="13px" height="13px" viewBox="0 0 13 13">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="broker-card-funnel" transform="translate(-76.000000, -158.000000)" fill="#FFFFFF">
                    <g id="Group-5-Copy-23">
                      <g id="Group-8" transform="translate(64.000000, 151.000000)">
                        <g id="Group-2" transform="translate(0.000000, 1.000000)">
                          <polygon id="Shape" points="18.447804 6 20.4421698 10.2476055 24.895608 10.9300745 21.671706 14.2303722 22.4268542 18.895608 18.447804 16.6954095 14.4687538 18.895608 15.223902 14.2303722 12 10.9300745 16.4534382 10.2476055"></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span className={styles.ratingTxt}>{card.broker_rating}</span>
            </span>
          </span>
          <span className={styles.countReviewsMain}>
            <span className={styles.countReviws}>{card.review_count}</span>
            <span className={styles.reviwsTxt}>
            {(card.review_count > 1) ? 'Reviews' : 'Review'}            
            </span>
          </span>
        </h4>)}
        {(card.review_count <= '0') && (<h4 className={cx(styles.totalReviews, styles.responsetimePart)}>
        <span className={styles.reviewRating}>
            <span className={styles.badge} style={{backgroundColor: '#B5B5B5'}}>
              <svg width="13px" height="13px" viewBox="0 0 13 13">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="broker-card-funnel" transform="translate(-76.000000, -158.000000)" fill="#FFFFFF">
                    <g id="Group-5-Copy-23">
                      <g id="Group-8" transform="translate(64.000000, 151.000000)">
                        <g id="Group-2" transform="translate(0.000000, 1.000000)">
                          <polygon id="Shape" points="18.447804 6 20.4421698 10.2476055 24.895608 10.9300745 21.671706 14.2303722 22.4268542 18.895608 18.447804 16.6954095 14.4687538 18.895608 15.223902 14.2303722 12 10.9300745 16.4534382 10.2476055"></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span className={styles.ratingTxt}>&mdash;</span>
            </span>
          </span>
          <span className={cx(styles.countReviewsMain, styles.responsTime)}>
            <span className={styles.countReviws}>{formatResponseTime(card.broker_details.avgResponse)}</span>
            <span className={styles.reviwsTxt}>
              {'Response time'}            
            </span>
          </span>
        </h4>)}
        
        <h5 className={styles.brokrLocation}>
          <svg width="11px" height="13px" viewBox="0 0 11 13">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="broker-card-funnel" transform="translate(-73.000000, -212.000000)" fill="#ff8a23" fillRule="nonzero">
                <g id="Group-5-Copy-23">
                  <g id="Group-7" transform="translate(73.000000, 210.000000)">
                    <g id="Group">
                      <path d="M5.53863077,2 C2.4528222,2 0,4.51482059 0,7.67862715 C0,9.2199688 0.553863077,10.6801872 1.66158923,11.7347894 C1.74071253,11.8159126 4.90564439,14.7363495 4.98476769,14.8174727 C5.30126088,15.0608424 5.77600066,15.0608424 6.01337055,14.8174727 C6.09249384,14.7363495 9.33654901,11.8159126 9.33654901,11.7347894 C10.4442752,10.6801872 10.9981382,9.2199688 10.9981382,7.67862715 C11.0772615,4.51482059 8.62443934,2 5.53863077,2 Z M5.53863077,9.30109204 C4.6682745,9.30109204 3.95616483,8.57098284 3.95616483,7.67862715 C3.95616483,6.78627145 4.6682745,6.05616225 5.53863077,6.05616225 C6.40898703,6.05616225 7.1210967,6.78627145 7.1210967,7.67862715 C7.1210967,8.57098284 6.40898703,9.30109204 5.53863077,9.30109204 Z" id="Shape"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span className={styles.locationtxt}> {card.location}</span>
          {/* {card.location} */}
        </h5>
        <p className={styles.brokrSumry}>
          “{truncateMsg(card.broker_details.latest_review, 30)}”
        </p>
        <p className={styles.brokrAbnAcl}>
          ABN: {card.broker_details.business_abn}
          <br />
          ACL/CRN: {card.broker_details.acl_crn}
        </p>
      </div>
    </div>
  );
};

export default BrokerCard;
