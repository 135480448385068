import React from "react";

// Define a placeholder image URL
import placeholderImage from "../assets/images/broker-img.png";

const S3Image = (props) => {
  if (props.src.match(/\/hashching\/uploads\/cropper_images\/20\d{12}\.png$/)) {
    // Try using the webp image as before
    const webpSrc = props.src.replace(".png", ".webp");

    return (
      <picture>
        <source type="image/webp" srcSet={webpSrc} />
        <img {...props} onError={(e) => (e.target.src = placeholderImage)} />
      </picture>
    );
  } else if (props.webp) {
    return (
      <picture>
        <source type="image/webp" srcSet={props.webp} />
        <img
          {...props}
          src={props.src || placeholderImage}
          onError={(e) => (e.target.src = placeholderImage)}
        />
      </picture>
    );
  } else {
    return (
      <img
        {...props}
        src={props.src || placeholderImage}
        onError={(e) => (e.target.src = placeholderImage)}
      />
    );
  }
};

export default S3Image;
