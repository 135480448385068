import React, { Component } from "react";
import cx from "classnames";
  
import { StaticQuery, graphql } from 'gatsby'; 
import Cookies from 'universal-cookie';
import Axios from "axios";
import { log, host, filterByState } from '../helpers/commons'
import BrokerCard from "../components/brokers/brokerCard";
import allKenticoCloudItemSectionBrokerFilter from "../../data/kentico/allKenticoCloudItemSectionBrokerFilter";

const PAGE_SIZE = 9;

class BrokerListing extends Component {
  constructor(props) {
    super(props);
    let postcode ='';
    let searchArea ='';
    //this.brokerFilterContent = props.data.allKenticoCloudItemSectionBrokerFilter;
    this.brokerFilterContent = allKenticoCloudItemSectionBrokerFilter;
    if (props.postcode) {
      postcode = props.postcode;
			searchArea=`${props.postcode}, ${props.suburb}, ${props.state}`
		}

    this.myRefBrokerListing = React.createRef() 
    this.state = {
      currentPage: 1,
      postcode: postcode,
      name: '',
      language: '',
      speciality: [],
      showMobileFilter: 'mobilefiterscreen',
      filterCount:true,
      searchArea:searchArea,
      brokerID: '',
      item: '',
      topLanguages: {
        edges: [
          {
            node: {
              language: 'English',
              total_count: 1500,
            },
          },
          {
            node: {
              language: 'Spanish',
              total_count: 900,
            },
          },
          {
            node: {
              language: 'French',
              total_count: 600,
            },
          },
          {
            node: {
              language: 'German',
              total_count: 400,
            },
          },
        ],
      }, // Replacing props.data.allHashchingTopLanguages.edges
    }
  }

  componentDidMount() {
		
		let data = this.props.data.allHashchingBroker.edges;
		let brokerId = '';
    Axios.get(host + '/gatsby-api/all-active-brokers-id?brokers=realtime').catch((error)  => {
      console.error(error);
    }).then((response)  => {
      if (response) {
        // brokerId = response.data.brokersId;
        // if (brokerId !== undefined) {
        //   this.setState({
        //     brokerID: brokerId
        //   });
        // }
      } else {
        console.log('no response');
      }
    })   
  }

  onChangePage(page) {
    this.setState({
      currentPage: page
    });
    if (typeof window !== 'undefined') {
     window.scrollTo(0, this.myRefBrokerListing.current.offsetTop)
   }
  }

  onChangeLocation(postcode,item) {
  
    if (!!this.props.postcodeUpdateHandler) {
      this.props.postcodeUpdateHandler(item, postcode)
    }
    
    log("onLocationUpdate===>",  postcode)

    this.setState({
      postcode: postcode,
      currentPage: 1,
      searchArea: item =="" ? "Australia" : `${item.postcode}, ${item.locality}, ${item.statecode}`,
      item: item
    });
  }

 
  onChangeName(name) {
    this.setState({
      name: name,
      currentPage: 1
    })
  }

  onChangeLanguage(language) {
    this.setState({
      language: language,
      currentPage: 1
    })
  }

  onChangeSpeciality(speciality) {
    this.setState({
      speciality: speciality,
      currentPage: 1
    })
  }

  sortInterstateBrokers(data) {
    return data.sort(function (a, b) {
      //console.log('============>>>', a.node.interstate)
      let va = a.node.interstate;
      let vb = b.node.interstate;
      if (va !== null) {
        va = Number(va);
      } else {
        va = 0
      }
      if (vb !== null) {
        vb = Number(vb);
      } else {
        vb = 0
      }
      if (va > vb) return -1;
      if (va < vb) return 1;
      return 0;
    });
  }
  
  openMobileFilter()
    {
      document.body.classList.add('modal-open-custom-mobile');
      this.setState({
        showMobileFilter: ''
      });
    }
    mobileFilterStatus(value)
    {
      this.setState({
        showMobileFilter: value
      });
    }
    sortBrokers(data) {
      return data.sort(function (a, b) {
        console.log('============>>>', a.distance)
        let va = a.distance;
        let vb = b.distance;
        return b.premium - a.premium;
      });
    }

     

  render() {

    const divStyle = {
      margin: '40px',
      border: '5px solid pink'
    };

    let data = JSON.parse(JSON.stringify(this.props.modified)); //cloning the object 
    console.log("===> modified", this.props.modified);     
    console.log("==> all brokers data", data);
    data = this.sortBrokers(data);
   
   
    return (
      <div>
        {data.map((  broker ) => {
          return (
            <div style={divStyle}>
              <p>{broker.firstName} {broker.lastName}</p>
            </div>
          )
        })}
      </div>
    )
  }
}



export default props => (
  <StaticQuery
    query={
      graphql`
  query {
    allHashchingBroker{
      edges {
         node {
          broker_id
          user_id
          firstName
          lastName
          fullName
          profile_pic
          review_count
          broker_rating
          languages
          premium
          sort_premium
          profile_type
          broker_postcode
          postcode
          state
          stateCode
          location
          specifications
          profile_view
          deals_count
          interstate
          distance
          monthly_leads
          broker_details {
            acl_crn
            business_abn
            avgResponse
            latest_review
            suburb
            state
          }
        }
      }
    }
  }
      `
    }
    render={(data) => {
      let i = 0;
      let modified = data.allHashchingBroker.edges.map(({ node }) => {
        node.sortingIndex = i++;
        return node;
      });
      return (
        <BrokerListing data={data} {...props} modified={modified} />
      ) 
    }}
  />
)